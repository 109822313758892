import moment from 'moment';
import { ajax, log } from '@/utils';

export default class News {
	IDStr = '';
	Title = '';
	Cover = '';
	Description = '';
	Content = '';
	Author = '';
	CreatedAt = new Date();
	Type = 0;
	TypeStr = '';
	CreatedAtStr = '';
	HotTime = 0;

	constructor(...data){
		if (data.length === 1){
			Object.assign(this, data[0]);
		}
		this.TypeStr = this.fnTypeStr();
		if (this.IDStr !== ''){
			this.CreatedAt = new Date(parseInt(this.IDStr.substring(0, 8), 16) * 1000);
			this.CreatedAtStr = moment(this.CreatedAt).format('YYYY-MM-DD');
		}
	}

	static GetOne(id){
		log.info('get /news', id);
		return ajax.get('/news', {
			params: {
				query: JSON.stringify({
					Filters: {
						'_id': 'objid:' + id,
					},
					Options: {
						Limit: 1,
					}
				})
			}
		});
	}

	static GetPageList(query){
		log.info('get: /news', query);				
		return ajax.get('/news', {
			params: {
				query: JSON.stringify(query)
			}
		});
	}

	Save(){
		log.info('post: /news');
		return ajax.post('/news', this);
	}

	static Delete(id){
		log.info('delete: /news' + id);
		return ajax.delete('/news/' + id);
	}

	static Trash(id){
		log.info('post: /news/trash/' + id);
		return ajax.post('/news/trash/' + id);
	}

	static Restore(id){
		log.info('post: /news/restore/' + id);
		return ajax.post('/news/restore/' + id);
	}

	static ToogleHot(id) {
		log.info('post: /news/hot/' + id);
		return ajax.post('/news/hot/' + id);
	}

	fnTypeStr(){
		switch(this.Type){
		case 1:
			return '企业新闻';
		case 2:
			return '行业动态';
		case 3:
			return '技术文档';
		}
		return '';
	}
};