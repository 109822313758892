import { log, ajax } from '@/utils';

export default {
	actions: {
		// 获取七牛上传token
		GetQiniuToken() {
			log.info('get /qiniu')
			return ajax.get('/qiniu');
		},

		// 上传图片
		UploadFile(_, { token, file}) {
			log.info('post /qiniu');
			let data = new FormData();
			data.append('token', token);
			data.append('file', file);
			return ajax.post('https://upload-z0.qiniup.com', data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				timeout: 30000,
			});
		}
	}
}