// utils/ajax.js
import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import Config from '@/utils/config'

const ajax = axios.create({
	baseURL: '/api',
	timeout: 5000,
});

/* process the http status error */
const err = (error) => {
	if (error.response) {
		// TODO: do something
	}
	store.commit('Notice', {msg: error.toString()});
	return Promise.reject(error);
};

ajax.interceptors.request.use((config) => {
	const token = Vue.ls.get(Config.ACCESS_TOKEN);
	if (token) {
		config.headers.Authorization = token;
	}
	if (config.method === 'post') {
		if ((!config.headers['Content-Type']) || (config.headers['Content-Type'] === '')) {
			config.data = JSON.stringify(config.data);
			config.headers['Content-Type'] = 'application/json';
		}		
	}
	return config;
});

ajax.interceptors.response.use((response) => {
	if (response.data['ErrCode']) {
		/* process the error code */
		if (response.data['ErrCode'] === 401) {			
			window.location.href = Config.LOGIN_PAGE;
		}
		store.commit('Notice', {msg: response.data['ErrMsg']});
		return Promise.reject(response.data);
	}

	return Promise.resolve(response.data);
}, err);

export default ajax;