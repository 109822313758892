import Vue from 'vue';
import Vuex from 'vuex';

import user from './user'
import ui from './ui'
import news from './news'
import upload from './upload'

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		user, ui, news, upload
	},	
});
