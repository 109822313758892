import Vue from 'vue'
import config from '@/utils/config'
import User from '@/model/user'
import router from '@/router'

export default {
	state: {
		user: User,
	},

	getters: {
		user: (state) => state.user,
	},

	mutations: {
		SetUser(state, user) {			
			Vue.ls.set(config.ACCESS_TOKEN, user.Token);			
			state.user = user;
		},
		Logout(state){
			state.user = new User();
			Vue.ls.remove(config.ACCESS_TOKEN);
			router.push('/login');
		}
	}
}