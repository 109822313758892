import store from '@/store';
import log from './log';
import Config from './config';

export default function(callback, value, meta){
	let filetype = '.pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4';
	switch(meta.filetype){
	case 'image':
		filetype = '.jpg, .jpeg, .png, .gif, .bmp';
		break;
	case 'media':
		filetype = '.mp3, .mp4';
		break;
	case 'file':
		filetype = '.pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx';
	}
	let input = document.createElement('input');
	input.setAttribute('type', 'file');
	input.setAttribute('accept', filetype);
	
	// 这里使用function(){}定义函数，而不能使用箭头函数，是为了拿到调用者的this，this.files获取用户所选文件
	// this指向了input以后，就无法指向vue对象了，因此要在上文使用that指向vue对象
	input.onchange = function(){
		let file = this.files[0];
		store.dispatch('GetQiniuToken').then((token) => {
			store.dispatch('UploadFile', {
				token: token,
				file: file
			}).then(res => {
				log.info(res);
				callback(Config.QiniuCDNBaseUrl + res.key, {text: file.name, title: file.name});
			}).catch(err => {
				log.warn(err);
			});
		})				
	}

	input.click();
};