<template>
	<v-app>
		<v-main>
			<router-view />
		</v-main>
		<v-snackbar
			v-model="notice.show" :color="notice.color" :timeout="notice.timeout"
			:right="true" :top="true"
		>
			{{ notice.msg }}
		</v-snackbar>
	</v-app>
</template>

<script>
// import store from './store'
import User from '@/model/user'
import log from '@/utils/log'
import { mapState, mapGetters } from 'vuex';
import {GetQueryString, Config} from '@/utils';

export default {
	name: 'App',

	data: () => ({
	}),

	computed: {
		...mapState({
			notice: state => state.ui.notice,
		}),
		...mapGetters(['user']),
	},

	created() {
		User.InitUser().Local();

		// receive token from SSO
		let token = GetQueryString('token');
		if(token && token !== ''){			
			// 使用token，向服务器请求解码，得到user数据
			User.CheckLoginWithSSO(token).then(data => {
				Object.assign(new User(), data).Local();
				this.$router.push('/admin/welcome');
			}).catch(err => {
				log.warn(err);
			})
		}else {
			if(this.user.Token && this.user.Token !== ''){
				this.$router.push('/admin/welcome');
			}else {
				window.location.href = Config.LOGIN_PAGE;
			}			
		}		
	},
};
</script>

<style>
html, .pretty-scroll{
	overflow-y: auto !important;
}
html::-webkit-scrollbar{
    width:10px;
}
html::-webkit-scrollbar-thumb{
    background-color:#BDBDBD;
    border-radius:5px;
}
.wt-100{
	width: 100% !important;
}
.cursor-pointer{
	cursor: pointer;
}
</style>