export default {
	state: {
		notice: {
			show: false,
			color: 'error',
			msg: '',
			timeout: 6000
		},
		overlay: false,
	},

	getters: {
		overlay: state => state.overlay,
		news_menu_open: state => state.news_menu_open,
	},

	mutations: {
		Notice(state, payload) {
			state.notice.show = true;
			if(payload.type){
				state.notice.color = payload.type;
			}else {
				state.notice.color = 'error';
			}
			state.notice.msg = payload.msg;
			if(payload.timeout){
				state.notice.timeout = payload.timeout;
			}else {
				state.notice.timeout = 6000;
			}
		},
	}
}