import Vue from 'vue'
import VueRouter from 'vue-router'
import WelcomePage from '@/pages/admin/WelcomPage'

// 防止重复路由
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
}

Vue.use(VueRouter)

const routes = [
	{
		path: '/admin',
		// route level code-splitting
		// this generates a separate chunk (adminFrame.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "adminPage" */ '../pages/AdminPage.vue'),
		children: [
			{path: 'welcome', component: WelcomePage},
			{
				path: 'news', 
				component: () => import('../pages/admin/news/NewsPage.vue'),
				children: [
					{path: 'list', component: () => import('../pages/admin/news/NewsListPage.vue')},
					{path: 'edit', component: () => import('../pages/admin/news/NewsEditPage.vue')},
					{path: 'edit/:id', component: () => import('../pages/admin/news/NewsEditPage.vue')},
					{path: 'trash', component: () => import('../pages/admin/news/NewsTrashPage.vue')},
				],
			},
			{
				path: 'setting', 
				component: () => import('../pages/admin/setting/SettingPage.vue'),
				children: [
					{path: 'image', component: () => import('../pages/admin/setting/Image.vue')},
					{path: 'language', component: () => import('../pages/admin/setting/Language.vue')},
					{path: 'template', component: () => import('../pages/admin/setting/Template.vue')},
				],
			},
		]
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
