class Config{}

Config.ACCESS_TOKEN = 'ACCESS_TOKEN';
Config.DEBUG_LEVEL = 0;
Config.LOGIN_PAGE_PREFIX = 'https://sylogin.saiyang.com.cn/?app=';
Config.APP = {
	ID:	10001,
	Secret:'Qk2j7zwpkpJ70pyC4h56-GHXkfRpJOpk'
}
Config.LOGIN_PAGE = Config.LOGIN_PAGE_PREFIX + window.btoa(JSON.stringify(Config.APP));
Config.NewsListPageSize = 10;
Config.QiniuCDNBaseUrl = 'https://files.hexcode.cn/';

export default Config;

