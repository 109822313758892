<template>
	<v-card class="text-center" elevation="3" style="height: calc(100vh - 120px);">
		<v-img class="d-inline-block" src="https://files.hexcode.cn/saiyang_1.png" width="40%" style="margin-top:calc(27vh - 183px);" />
		<h2 class="text-center mt-16">
			请点击左侧功能面板中的条目进行操作，如果没有权限请联系管理员
		</h2>		
	</v-card>
</template>

<script>
export default {
	name: 'WelcomPage',
	mounted(){

	}
}
</script>

<style scoped>
h2 {
	color: #727172;
}
</style>