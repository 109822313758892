import ajax from '@/utils/ajax'
import log from '@/utils/log'
import Vue from 'vue'
import store from '@/store'
import config from '@/utils/config'
import user from '@/store/user'

class User {
	IDStr = '';
	Name = '';
	Token = '';	// 本系统令牌，单点登陆后生成
	Power = 0;	// 本系统权限，单点注册后后台调整

	/* 下方数据来自于单点登录系统 */
	Avatar = '';
	Email = '';
	Phone = '';
	StaffCode = '';

	constructor(...args){
		if (args.length == 0){
			this.IDStr = '';
			this.Name = '';
			this.Token = '';
			this.Power = 0;
			this.Department = 0;
		}else if(args.length == 1){
			this.IDStr = args[0].IDStr;
			this.Name = args[0].Name;
			this.Token = args[0].Token;
			this.Power = args[0].Power;
			this.Department = args[0].Department;
		}
	}

	// 初始化User，如果本地ls中有user字段，则读取本地数据，否则新建一个空User，并保存到本地
	static InitUser(){
		// build a new User by ls
		let token = Vue.ls.get(config.ACCESS_TOKEN);
		let user = new User();
		if (token != null){
			user.Token = token;
		}

		return user;
	}

	// 使用用户名密码登录
	static Login(staffCode, password){
		log.info('post:/user/login');
		return ajax.post('/user/login', {StaffCode: staffCode, Password: password});
	}

	static Logout(){
		store.commit('SetUser', new User());
	}

	// 使用token验证登录，token已经被封装在请求头部
	CheckLoginWithToken(){
		log.info('post:/user/check');
		return ajax.post('/user/check', user.Token);
	}

	// 使用单点登录系统返回的数据，登录系统
	// 后台校验app.callback_pwd，如果系统没有用户，创建用户，返回用户给前台，携带token
	static CheckLoginWithSSO(token){
		log.info('post:/user/sso');
		return ajax.post('/user/sso', {Token: token});
	}

	// 本地化一个user实例
	Local(){
		store.commit('SetUser', this);
		return this;
	}

	// 使用参数修改Server端用户数据，并存储到Local
	Save(){
		log.info('post:/user/save', this);
		return ajax.post('/user/save', this);
	}

}

export default User