import Config from './config';
import log from './log';
import ajax from './ajax';
import moment from 'moment';
import upload from './upload';

function GetQueryString(name) {
	let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
	let r = window.location.search.substring(1).match(reg);
	if (r!=null)
	 return decodeURIComponent(r[2]);
	return null;
}

function GetHexFromDayStart(month) {
	return 'objid:' + moment(month).unix().toString(16) + '0000000000000000';
}

function GetHexFromDayEnd(month) {
	return 'objid:' + (moment(month).unix() + 3600 * 24 ).toString(16) + '0000000000000000';
}

export {GetQueryString, Config, log, ajax, GetHexFromDayStart, GetHexFromDayEnd, upload };